import { createRoot } from 'react-dom/client';
import { Logo } from '@/components/lv1Atoms/Logo';
import dfLogo from '@/src/assets/logo.png';

const container = document.getElementById('logo');
if (container) {
  const root = createRoot(container);
  root.render(
    <Logo img={dfLogo} size={'mx-auto w-96 h-auto'}  />
  );
}
